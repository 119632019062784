import React from 'react';
import { BsGithub, BsInstagram } from 'react-icons/bs';
import { FaFacebookF } from 'react-icons/fa';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href='https://github.com/diwas7777' target='_blank_'><BsGithub /></a>
    </div>
    <div>
      <a href='https://facebook.com/diwas7777' target='_blank_'><FaFacebookF /></a>
    </div>
    <div>
      <a href='https://instagram.com/diwas.exe' target='_blank_'><BsInstagram /></a>
    </div>
  </div>
);

export default SocialMedia;